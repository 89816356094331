import React from 'react'
import FooterPublic from './Common/FooterPublic'
import HeaderPublic from './Common/HeaderPublic'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import rightImage from '../../assets/images/quantum/hero_img.svg'
import aboutIMG from '../../assets/images/quantum/qtm1.png'
import aboutIMG_2 from '../../assets/images/quantum/img_2.png'
import why_1 from '../../assets/images/quantum/why_choose/1.svg'
import why_2 from '../../assets/images/quantum/why_choose/2.svg'
import why_3 from '../../assets/images/quantum/why_choose/3.svg'
import why_4 from '../../assets/images/quantum/why_choose/4.svg'
import why_5 from '../../assets/images/quantum/why_choose/5.svg'
import why_6 from '../../assets/images/quantum/why_choose/6.svg'
import webApplication from '../../assets/images/quantum/test_automation/1.svg'
import mobileApplication from '../../assets/images/quantum/test_automation/2.svg'
import sap from '../../assets/images/quantum/test_automation/3.svg'
import api from '../../assets/images/quantum/test_automation/4.svg'
import as400 from '../../assets/images/quantum/test_automation/5.svg'
import jira from '../../assets/images/quantum/tools/jira.png'
import alm from '../../assets/images/quantum/tools/alm.png'
import './Home.scss'
import { Link } from 'react-router-dom'
const Home = () => {

  const whyChoose = [
    {
      icon: `${why_1}`,
      title: "Scriptless Automation",
      content: "Quantum offers low-code framework that enables the team to automate more in a short time."
    },
    {
      icon: `${why_2}`,
      title: "Parallel Execution",
      content: "Quantum offers parallel execution that allows multiple test on different browsers and environments simultaneously."
    },
    {
      icon: `${why_3}`,
      title: "Supports Multiple platform",
      content: "Quantum provides a multiple platform interface to work across applications, platforms, devices, and technologies without complicated context switching."
    },
    {
      icon: `${why_4}`,
      title: "Customized Reporting",
      content: "Quantum’s reporting module offers wide range of reports that can be configured and customized to fit all projects."
    },
    {
      icon: `${why_5}`,
      title: "Impact Analysis",
      content: "Quantum’s impact analysis module assists the user to verify the impact when any changes are made to business keyword. It shows all testcases in which the particular business keyword is mapped."
    },
    {
      icon: `${why_6}`,
      title: "CI/CD Integration",
      content: "Quantum supports CI/CD pipeline thereby test every incremental build much faster."
    },

  ]

  return (
    <>
      <HeaderPublic />
      <div className='CP_public_home' >
        <div className='hero_section'>
          <div className='hero_section_wrapper'>
            <div className='hero_left'>
              <h1>Empower your Teams with Swift and Reliable Automation Test Platform</h1>
              <p>Build end-to-end test with 5x increased productivity using this no-code/low-code framework</p>
              {/* <Link to='/demorequest' className='btn btn_white d-inline-flex align-items-center justify-content-center'>Get Demo</Link> */}
            </div>
            <div className='hero_right'>
              <div className='hero_right_image'>
                <img src={rightImage} alt='banner' width='812' height='495' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
        <section className='body_wrapper mb-4'>
          <div className='container-fluid'>
            <div className='row qntm-wrapper align-items-center justify-content-center g-4'>
              <div className='col-lg-5'>
                <div className='layer_image'>
                  <img src={aboutIMG} alt='about' width='544' height='364' className='img-fluid' />
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='layer_content'>
                  <h1>Automate Faster with Optimal Coverage</h1>
                  <ul className='list'>
                    <li className='my-2'>With Quantum, you can easily automate your tests and run them faster and more reliably than ever before.</li>
                    <li className='my-2'>Our user-friendly interface makes it simple for even non-technical users to create and execute tests.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='body_wrapper bg-dullWhite pb-5'>
          <div className='container'>
            <div className='row g-0'>
              <div className='col-md-12 pb-3'>
                <div className='layer_content text-center'>
                  <h1>Move Faster with Modern Frameworks</h1>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='layer_content pt-5'>
                  <h4>Keyword-Driven</h4>
                  <ul className='list'>
                    <li className='my-2'>Quantum’s Keyword-Driven framework is ideal for many projects, environments and objects.</li>
                    <li className='my-2'>In this framework, the test logic is divided into keywords and functions.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='image-panel'>
                  <img src={aboutIMG_2} className='img-fluid' alt='img' width='596' height='364' />
                </div>
                <div className='layer_content mt-4 ps-2'>
                  <h4>Business Process Model and Notation (BPMN)</h4>
                  <ul className='list'>
                    <li className='my-2'>Quantum supports diagrammatic representation of the application flow or scenarios.</li>
                    <li className='my-2'>It helps with generating all possible combination of Testcases.</li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='layer_content pt-5'>
                  <h4>Behaviour Driven Development (BDD)</h4>
                  <ul className='list'>
                    <li className='my-2'>BDD framework provides a structured way of writing tests.</li>
                    <li className='my-2'>Allowing for better communication and easier test maintenance.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='body_wrapper'>
          <div className='container'>
            <div className='row g-4'>
              <div className='col-md-12'>
                <div className='layer_content text-center'>
                  <h1>Test Automation for all Application Types and Platforms</h1>
                </div>
              </div>
              <div className='col-md-12 icon-tab d-none d-lg-block'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="webApplication" >
                  <Nav variant="pills" className="justify-content-center g-3 row">
                    <Nav.Item className='col'>
                      <Nav.Link eventKey="webApplication">
                        <div className='tab-button'>
                          <img src={webApplication} width='60' height='55' className="img-fluid" alt='ico' />
                          <h3>Web Applications</h3>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='col'>
                      <Nav.Link eventKey="mobileApplication">
                        <div className='tab-button'>
                          <img src={mobileApplication} width='40' height='53' className="img-fluid" alt='ico' />
                          <h3>Mobile Applications</h3>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='col'>
                      <Nav.Link eventKey="sap">
                        <div className='tab-button'>
                          <img src={sap} width='48' height='55' className="img-fluid" alt='ico' />
                          <h3>SAP</h3>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='col'>
                      <Nav.Link eventKey="api">
                        <div className='tab-button'>
                          <img src={api} width='64' height='53' className="img-fluid" alt='ico' />
                          <h3>API</h3>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='col'>
                      <Nav.Link eventKey="as400">
                        <div className='tab-button'>
                          <img src={as400} width='62' height='50' className="img-fluid" alt='ico' />
                          <h3>AS400</h3>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className='pt-5'>
                    <div className='container'>
                      <div className='row justify-content-center'>
                        <div className='col-md-9'>
                          <Tab.Pane eventKey="webApplication">
                            <div className='icon-tab-content'>
                              <h3>Web Applications</h3>
                              <p className='mt-3'>Quantum helps in creating reusable and robust test suite for all web applications including modern frameworks like Angular, React JS etc.</p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="mobileApplication">
                            <div className='icon-tab-content'>
                              <h3>Mobile Applications</h3>
                              <p className='mt-3'>Quantum’s Mobile Automation studio helps to automate the real and virtual devices (iOS & Android) by connecting through Appium.</p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="sap">
                            <div className='icon-tab-content'>
                              <h3>SAP</h3>
                              <p className='mt-3'>Quantum’s script recording and playback feature can automate business transactions to master data creation, and configuration etc.</p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="api">
                            <div className='icon-tab-content'>
                              <h3>API</h3>
                              <p className='mt-3'>Quantum offers a complete and exclusive API testing tool that makes it simple to set up automated tests. All your test and requests can be aggregated into a single automated test sequence.</p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="as400">
                            <div className='icon-tab-content'>
                              <h3>AS400</h3>
                              <p className='mt-3'>AS400 terminal- based application is a process that connects and automates through IBM EHLLAPI BRIDGE.</p>
                            </div>
                          </Tab.Pane>
                        </div>
                      </div>
                    </div>
                  </Tab.Content>

                </Tab.Container>
              </div>
              <div className='col-md-12 icon-tab d-block d-lg-none'>
                <Accordion defaultActiveKey="web_Application" flush>
                  <Accordion.Item eventKey="web_Application">
                    <Accordion.Header>
                      <img src={webApplication} width='30' height='30' className="img-fluid" alt='ico' />
                      Web Applications
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Quantum helps in creating reusable and robust test suite for all web applications including modern frameworks like Angular, React JS etc.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="mobile_Application">
                    <Accordion.Header>
                      <img src={mobileApplication} width='30' height='30' className="img-fluid" alt='ico' /> Mobile Applications
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Quantum’s Mobile Automation studio helps to automate the real and virtual devices (iOS & Android) by connecting through Appium.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="sap_mobile">
                    <Accordion.Header>
                      <img src={sap} width='30' height='30' className="img-fluid" alt='ico' /> SAP
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Quantum’s script recording and playback feature can automate business transactions to master data creation, and configuration etc.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="api_mobile">
                    <Accordion.Header>
                      <img src={api} width='30' height='30' className="img-fluid" alt='ico' /> API
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Quantum offers a complete and exclusive API testing tool that makes it simple to set up automated tests. All your test and requests can be aggregated into a single automated test sequence.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="as400_mobile">
                    <Accordion.Header>
                      <img src={as400} width='30' height='30' className="img-fluid" alt='ico' />  AS400
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>AS400 terminal- based application is a process that connects and automates through IBM EHLLAPI BRIDGE.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <section className='body_wrapper bg-dullWhite'>
          <div className='container'>
            <div className='tools-wrapper'>
              <div className='layer_content'>
                <h5>Integrate with your Existing Tools</h5>
              </div>
              <div className='divider'></div>
              <div className='img-panel'>
                <img className='img-fluid' src={jira} width='145' height='52' alt='ico' />
                <img className='img-fluid' src={alm} width='165' height='52' alt='ico' />
              </div>
            </div>
          </div>
        </section>
        <section className='body_wrapper mt-3'>
          <div className='container'>
            <div className='row g-4'>
              <div className='col-md-12'>
                <div className='layer_content text-center'>
                  <h1>Why To Choose Quantum?</h1>
                </div>
              </div>
              {
                whyChoose.map((data, index) => (
                  <div className='col-lg-4 col-md-6' key={index}>
                    <div className='whyChoose'>
                      <div className='icon_panel'>
                        <img src={data.icon} width="80" height="80" alt="icon" className='img-fluid' />
                      </div>
                      <h3>{data.title}</h3>
                      <p>{data.content}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
      </div>
      <FooterPublic />
    </>
  )
}

export default Home