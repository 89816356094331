import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/images/logo/QTlogo.png'
import './Common.scss'
const HeaderPublic = () => {

  useEffect(() => {
    addSticky();
  }, [])


  const addSticky = () => {
    window.onscroll = () => {
      if (window.scrollY >= 100) {
        document.getElementById("publicheader").classList.add("publicsticky");
      } else {
        document.getElementById("publicheader").classList.remove("publicsticky");
      }
    };
  };

  return (
    <header id='publicheader' className='CP_header'>
      <div className='logo'>
        <Link to='/home'><img src={Logo} alt='logo' /></Link>
      </div>
      <div className='buttonBlock'>
        <a href='https://quantum.qassure.com/Quantum' target='_blank' className='btn btn_outline'>Create an Account</a>
        <a href='https://quantum.qassure.com/Quantum' target='_blank' className='btn btn_primary'>Sign In</a>
      </div>
    </header>
  )
}

export default HeaderPublic